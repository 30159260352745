import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink, useParams } from "react-router-dom";
import { usePeticion, ESTADOS, Exito } from "../herramientas";
import { API_URL } from "../config";

export default function Index() {
  const nombreOperacion = "Declarar entrega";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [datos, setDatos] = useState({});
  const [detalle, setDetalle] = useState();

  const navigate = useNavigate();
  const peticion = usePeticion();
  const { id_pedido } = useParams();

  useEffect(inicializar, []);

  function inicializar() {
    peticion("vl01n/declarar_entrega/" + id_pedido, {})
      .then((json) => {
        setDatos(json.datos);
        setDetalle(json.detalle);
        setEstado(ESTADOS.CARGADO);
        if (json.datos.estado != "completado") {
          alert("Este pedido no está completado");
          navigate("../");
        }
      })
      .catch((e) => {
        alert("No ha podido ser cargado el pedido: " + e.message);
      });
  }

  function handleDeclararEntrega(e) {
    e.preventDefault();
    setEstado(ESTADOS.CARGANDO)
    let formData = new FormData(e.currentTarget)
    formData.append("id_pedido", id_pedido)
    peticion("vl01n/declarar_entregado/", {method: "POST", body: formData})
    .then(json=>{
      setEstado(ESTADOS.EXITO)      
    }).catch(e=>alert("No pudo ser declarado como entregado el pedido: "+e))
  }

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === ESTADOS.EXITO) {
    contenido = (
      <Exito titulo="Entrega declarada con éxito" mensaje="La entrega ha sido declarada de forma satisfactoria">
        <button type="button" className="btn btn-primary" onClick={()=>navigate("../")}>
          Declarar otra entrega
        </button>
      </Exito>
    )
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (
      <>
        <h2>Datos</h2>
        <div className="row">
          <div className="col-md">
            <table className="table">
              <tbody>
                <tr>
                  <th className="text-center" scope="col">
                    Cliente
                  </th>
                  <td className="align-middle text-center">{datos["nombre_cliente"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="col">
                    Vendedor
                  </th>
                  <td className="align-middle text-center">{datos["nombre_vendedor"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="col">
                    Fecha
                  </th>
                  <td className="align-middle text-center">{`${new Date(datos["fecha"]).getUTCDate()}/${new Date(datos["fecha"]).getUTCMonth() + 1}/${new Date(
                    datos["fecha"]
                  ).getUTCFullYear()}`}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="col">
                    Costo de embalaje
                  </th>
                  <td className="align-middle text-center">S/ {datos["costo_envio"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="col">
                    Total
                  </th>
                  <td className="align-middle text-center">S/ {datos["total"]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <h2>Detalle</h2>
        <div className="table-responsive">
          <table className="table table-hover table-sm table-bordered">
            <thead>
              <tr>
                <th className="align-middle text-center" scope="col">
                  Buen Est.
                </th>
                <th className="align-middle text-center" scope="col">
                  Para Liq.
                </th>
                <th className="align-middle text-center" scope="col">
                  Merma
                </th>
                <th className="align-middle text-center" scope="col">
                  Imagen
                </th>
                <th className="align-middle text-center" scope="col">
                  Ítem
                </th>
                <th className="align-middle text-center" scope="col">
                  Precio
                </th>
                <th className="align-middle text-center" scope="col">
                  Importe
                </th>
              </tr>
            </thead>
            <tbody id="tabla">
              {detalle.map((item) => (
                <tr>
                  <td className="align-middle text-center">{item["cantidad_buen_estado"]}</td>
                  <td className="align-middle text-center">{item["cantidad_para_liquidacion"]}</td>
                  <td className="align-middle text-center">{item["cantidad_merma"]}</td>
                  <td className="align-middle text-center">
                    <img
                      alt=""
                      src={API_URL + "static/img/productos/" + item["id_producto"] + "/principal.jpeg"}
                      className="rounded"
                      style={{ height: "5rem" }}
                    />
                  </td>
                  <td className="align-middle text-center">{item["nombre_producto"]}</td>
                  <td className="align-middle text-center">{item["precio_final"]}</td>
                  <td className="align-middle text-center">{item["importe"]}</td>
                </tr>
              ))}
              <tr>
                <th colSpan="3" className="align-middle text-center"></th>
                <th colSpan="3" className="align-middle text-center">
                  Costo de embalaje
                </th>
                <th className="align-middle text-center">{datos["costo_envio"]}</th>
              </tr>
              <tr>
                <th colSpan="3" className="align-middle text-center"></th>
                <th colSpan="3" className="align-middle text-center">
                  Total
                </th>
                <th className="align-middle text-center">{datos["total"]}</th>
              </tr>
            </tbody>
          </table>
        </div>
        <h2>
          Observaciones{" "}
          <Link role="button" to={"../modificar_observaciones/" + id_pedido} className="btn btn-warning btn-sm">
            <i className="bi bi-pencil"></i> Modificar
          </Link>
        </h2>
        <p>
          {datos["observaciones"].split("\n").map((parrafo) => {
            return (
              <>
                {parrafo}
                <br />
              </>
            );
          })}
        </p>
        {datos.tipo_entrega == "Envío" ? (
          <>
            <h2>Datos de entrega</h2>
            <form className="row g-3" onSubmit={handleDeclararEntrega}>
              <div className="col-md-6">
                <label for="foto_embalaje" className="form-label">
                  Foto de productos embalados
                </label>
                <input type="file" accept="image/*" className="form-control" id="foto_embalaje" name="foto_embalaje" />
              </div>
              <div className="col-md-6">
                <label for="imagen_comprobante_agencia" className="form-label">
                  Imagen de comprobante de la agencia
                </label>
                <input type="file" accept="image/*" className="form-control" id="imagen_comprobante_agencia" name="imagen_comprobante_agencia" />
              </div>
              <div className="col-md">
                <button type="submit" className="btn btn-success me-3 mb-3">
                  Declarar entregado
                </button>
                <button type="button" onClick={() => navigate(-1)} className="btn btn-danger me-3 mb-3">
                  Cancelar
                </button>
              </div>
            </form>
          </>
        ) : (
          <form className="row g-3" onSubmit={handleDeclararEntrega}>
            <input type="file" accept="image/*" className="form-control d-none" name="foto_embalaje" />
            <input type="file" accept="image/*" className="form-control d-none" name="imagen_comprobante_agencia" />
            <div className="col-md">
              <button type="submit" className="btn btn-success me-3 mb-3">
                Declarar entregado
              </button>
              <button type="button" onClick={() => navigate(-1)} className="btn btn-danger mb-3 me-3">
                Cancelar
              </button>
            </div>
          </form>
        )}
      </>
    );
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      {contenido}
    </main>
  );
}
