import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './css/custom.css';

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Navigate,
  Outlet
} from "react-router-dom";

import IniciarSesion from './iniciar_sesion/index'

import { Error } from './confirmaciones'

import Smen from './smen/index' 

import Xd01 from './xd01/index'
import Xd02 from './xd02/index'
  import Xd02Modificar from './xd02/modificar'
import Xd03 from './xd03/index'
import Zxd03 from './zxd03/index'

import Xd03Perfil from './xd03/perfil'
import Mm01 from './mm01/index'
import Mm02 from './mm02/index'
  import Mm02Modificar from './mm02/modificar'
import Mm03 from './mm03/index'
  import Mm03Detalle from './mm03/detalle'
import Mm11 from './mm11/index'
import Mm12 from './mm12/index'
  import Mm12Modificar from './mm12/modificar'
import Mm13 from './mm13/index'
  import Mm13Detalle from './mm13/detalle'
import Mi04 from './mi04/index'
  import Mi04Producto from './mi04/producto'
import Mb51 from './mb51/index'

import Va01 from './va01/index'
import Va02 from './va02/index'
  import Va02Modificar from './va02/modificar'
  import Va02ModificarDatos from './va02/modificar_datos'
  import Va02AgregarItem from './va02/agregar_item'
  import Va02ModificarItem from './va02/modificar_item'
import Va12 from './va12/index'
  import Va12Modificar from './va12/modificar'
  import Va12ModificarDatos from './va12/modificar_datos'
  import Va12AgregarItem from './va12/agregar_item'
  import Va12ModificarItem from './va12/modificar_item'
import Va03 from './va03/index'
  import Va03Consultar from './va03/consultar'
import Vl01n from './vl01n/index'
  import Vl01nDeclararEntrega from './vl01n/declarar_entrega'
  import Vl01nModificarObservaciones from './vl01n/modificar_observaciones'
import Z03 from './z03/index'
import Zva03 from './zva03/index'

import Su01 from './su01/index'
  import Su01Modificar from './su01/modificar'

import EstadisticasVendedores from './estadisticas_vendedores/index'

import EstadisticasAdministracion from './estadisticas_administracion/index'

console.clear()

function Proteger(props) {
  if (localStorage.getItem("token") == null) {
    return <Navigate to="/iniciar_sesion"></Navigate>
  } else {
    return <App {...props} ><Outlet/></App>
  }
}

const router = [
  {
    path: "iniciar_sesion",
    element: <IniciarSesion />,
  },
  {
    path: "", element: <Proteger />, errorElement: <App><Error /></App> ,children: [
      {path: "", element: <Navigate to="smen" />},
      {path: "smen", element: <Smen />},
      {path: "xd01", element: <Xd01 />},
      {path: "xd02", element: <Outlet />, children: [
        {path: "", element: <Xd02 />},
        {path: "modificar/:id_cliente", element: <Xd02Modificar />}
      ]},
      {path: "xd03", element: <Outlet />, children: [
        {path: "", element: <Xd03 />},
        {path: "perfil/:id_cliente", element: <Xd03Perfil />}
      ]},
      {path: "zxd03", element: <Zxd03 />},


      {path: "mm01", element: <Mm01 />},
      {path: "mm02", element: <Outlet />, children: [
        {path: "", element: <Mm02 />},
        {path: "modificar/:id_producto", element: <Mm02Modificar />}
      ]},
      {path: "mm03", element: <Outlet />, children: [
        {path: "", element: <Mm03 />},
        {path: "detalle/:id_producto", element: <Mm03Detalle />}
      ]},
      {path: "mm11", element: <Mm11 />},
      {path: "mm12", element: <Outlet />, children: [
        {path: "", element: <Mm12 />},
        {path: "modificar/:id_producto", element: <Mm12Modificar />}
      ]},
      {path: "mm13", element: <Outlet />, children: [
        {path: "", element: <Mm13 />},
        {path: "detalle/:id_producto", element: <Mm13Detalle />}
      ]},
      {path: "mi04", element: <Outlet />, children: [
        {path: "", element: <Mi04 />},
        {path: "producto/:id_producto", element: <Mi04Producto />}
      ]},
      {path: "mb51", element: <Mb51 />},

      {path: "va01", element: <Va01 />},
      {path: "va02", element: <Outlet />, children: [
        {path: "", element: <Va02 />},
        {path: "modificar/:id_pedido", element: <Va02Modificar />},
        {path: "modificar_datos/:id_pedido", element: <Va02ModificarDatos />},
        {path: "agregar_item/:id_pedido", element: <Va02AgregarItem />},
        {path: "modificar_item/:id_articulo_pedido", element: <Va02ModificarItem />}
      ]},
      {path: "va12", element: <Outlet />, children: [
        {path: "", element: <Va12 />},
        {path: "modificar/:id_pedido", element: <Va12Modificar />},
        {path: "modificar_datos/:id_pedido", element: <Va12ModificarDatos />},
        {path: "agregar_item/:id_pedido", element: <Va12AgregarItem />},
        {path: "modificar_item/:id_articulo_pedido", element: <Va12ModificarItem />}
      ]},
      {path: "va03", element: <Outlet />, children: [
        {path: "", element: <Va03 />},
        {path: "consultar/:id_pedido", element: <Va03Consultar />}
      ]},
      {path: "vl01n", element: <Outlet />, children: [
        {path: "", element: <Vl01n />},
        {path: "declarar_entrega/:id_pedido", element: <Vl01nDeclararEntrega />},
        {path: "modificar_observaciones/:id_pedido", element: <Vl01nModificarObservaciones />}
      ]},
      {path: "z03", element: <Z03 />},
      {path: "zva03", element: <Zva03 />},

      {path: "su01", element: <Outlet />, children: [
        {path: "", element: <Su01 />},
        {path: "modificar/:id_usuario", element: <Su01Modificar />}
      ]},

      {path: "estadisticas_vendedores", element: <EstadisticasVendedores />},

      {path: "estadisticas_administracion", element: <EstadisticasAdministracion />}
    ]
  }
]


// router.forEach(ruta => {
//   if (ruta.path=="") {
//     ruta.children.forEach(subRuta => {
//       subRuta.errorElement = <Error />
//     });
//   }
// });

const asd = createBrowserRouter(router)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={asd} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
