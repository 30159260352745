import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink, useParams } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";
import { API_URL } from "../config";

export default function Index() {
  const nombreOperacion = "Consultar pedido";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [datos, setDatos] = useState({});
  const [detalle, setDetalle] = useState();

  const navigate = useNavigate();
  const peticion = usePeticion();
  const { id_pedido } = useParams();

  useEffect(inicializar, []);

  function inicializar() {
    peticion("va03/consultar_pedido/" + id_pedido, {})
      .then((json) => {
        setDatos(json.datos);
        setDetalle(json.detalle);
        setEstado(ESTADOS.CARGADO);
      })
      .catch((e) => {
        alert("No ha podido ser cargado el pedido: " + e.message);
      });
  }

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (
      <>
        <h2>Datos</h2>
        <div className="row">
          <div className="col-md">
            <table className="table">
              <tbody>
                <tr>
                  <th className="text-center" scope="col">
                    Número
                  </th>
                  <td className="align-middle text-center">{datos["id_pedido"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="col">
                    Estado
                  </th>
                  <td className="align-middle text-center">{datos["estado"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="col">
                    Cliente
                  </th>
                  <td className="align-middle text-center">{datos["nombre_cliente"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="col">
                    Vendedor
                  </th>
                  <td className="align-middle text-center">{datos["nombre_vendedor"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="col">
                    Fecha
                  </th>
                  <td className="align-middle text-center">{`${new Date(datos["fecha"]).getUTCDate()}/${new Date(datos["fecha"]).getUTCMonth() + 1}/${new Date(
                    datos["fecha"]
                  ).getUTCFullYear()}`}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="col">
                    Tipo de entrega
                  </th>
                  <td className="align-middle text-center">{datos["tipo_entrega"]}</td>
                </tr>
                {datos.tipo_entrega == "Envío" && (
                  <tr>
                    <th className="text-center" scope="col">
                      Costo de embalaje
                    </th>
                    <td className="align-middle text-center">S/ {datos["costo_envio"]}</td>
                  </tr>
                )}
                <tr>
                  <th className="text-center" scope="col">
                    Total
                  </th>
                  <td className="align-middle text-center">S/ {datos["total"]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col">
            <Link role="button" to="../" className="btn btn-secondary w-100 h-100">
              Regresar
            </Link>
          </div>
        </div>
        <h2>Detalle</h2>
        <div className="table-responsive">
          <table className="table table-hover table-sm table-bordered">
            <thead>
              <tr>
                <th className="align-middle text-center" scope="col">
                  Cant.
                </th>
                <th className="align-middle text-center" scope="col">
                  Imagen
                </th>
                <th className="align-middle text-center" scope="col">
                  Ítem
                </th>
                <th className="align-middle text-center" scope="col">
                  Fecha
                </th>
                <th className="align-middle text-center" scope="col">
                  Precio
                </th>
                <th className="align-middle text-center" scope="col">
                  Importe
                </th>
                <th colSpan={2} className="align-middle text-center" scope="col">
                  Pagado
                </th>
              </tr>
            </thead>
            <tbody id="tabla">
              {detalle.map((item) => (
                <tr>
                  <td className="align-middle text-center">{item["cantidad"]}</td>
                  <td className="align-middle text-center">
                    <img
                      alt=""
                      src={API_URL + "static/img/productos/" + item["id_producto"] + "/principal.jpeg"}
                      className="rounded"
                      style={{ height: "5rem" }}
                    />
                  </td>
                  <td className="align-middle text-center">{item["nombre_producto"]}</td>
                  <td className="align-middle text-center">{(new Date(item["fecha"]).toLocaleString())}</td>
                  <td className="align-middle text-center">{item["precio_final"]}</td>
                  <td className="align-middle text-center">{item["importe"]}</td>
                  {item.pagado ? <td className="align-middle text-center table-success">Sí</td> : <td className="align-middle text-center table-danger">No</td>}
                  <td className="align-middle text-center">{item["numero_operacion"]}</td>
                </tr>
              ))}
              <tr>
                <th colSpan="2" className="align-middle text-center"></th>
                <th colSpan="2" className="align-middle text-center">
                  Costo de embalaje
                </th>
                <th className="align-middle text-center">{datos["costo_envio"]}</th>
              </tr>
              <tr>
                <th colSpan="2" className="align-middle text-center"></th>
                <th colSpan="2" className="align-middle text-center">
                  Total
                </th>
                <th className="align-middle text-center">{datos["total"]}</th>
              </tr>
            </tbody>
          </table>
        </div>
        <h2>Observaciones</h2>
        <p>
          {datos["observaciones"].split("\n").map((parrafo) => {
            return (
              <>
                {parrafo}
                <br />
              </>
            );
          })}
        </p>
        {datos.tipo_entrega == "Envío" && datos.estado == "cerrado" && (
          <>
            <h2>Información de entrega</h2>
            <table className="table">
              <tbody>
                <tr>
                  <th className="text-center" scope="col">
                    Entregado por
                  </th>
                  <td className="align-middle text-center">{datos["nombre_usuario_entrega"]}</td>
                </tr>
              </tbody>
            </table>
            <div className="row g-3">
              <div className="col-md-6">
              <h3>Foto embalaje</h3>
            <img src={API_URL+"static/img/entregas/"+id_pedido+"/foto_embalaje.jpeg"} className="rounded w-100" alt="No hay foto"></img>

              </div>
              <div className="col-md-6">
              <h3>Imagen de comprobante de la agencia</h3>
            <img src={API_URL+"static/img/entregas/"+id_pedido+"/imagen_comprobante_agencia.jpeg"} className="rounded w-100" alt="No hay imagen"></img>

              </div>
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      {contenido}
    </main>
  );
}
