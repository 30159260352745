import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";
import { API_URL } from "../config";

export default function Index() {
  const nombreOperacion = "Exportar PDF de pedido";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [pedidos, setPedidos] = useState([]);
  const [pedidosFiltrados, setPedidosFiltrados] = useState([]);
  const [filtroEstado, setFiltroEstado] = useState("todos");
  const [busqueda, setBusqueda] = useState("");
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [peticionesTransito, setPeticionesTransito] = useState(0);

  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(() => {
    consultar();
  }, []);

  function consultar() {
    setPeticionesTransito((prev) => prev + 1);
    let formData = new FormData();
    formData.append("pagina", pagina);
    formData.append("busqueda", busqueda);
    formData.append("filtroEstado", filtroEstado);
    peticion("z03/pedidos/", {
      method: "POST",
      body: formData,
    })
      .then((json) => {
        setPedidos(json.pedidos);
        setPedidosFiltrados(json.pedidos);
        setTotalPaginas(Math.ceil(json.paginas));
        setEstado(ESTADOS.CARGADO);
        setPeticionesTransito((prev) => prev - 1);
      })
      .catch((e) => {
        alert("No han podido ser cargados los pedidos correctamente: " + e.message);
        setEstado(ESTADOS.CARGADO);
        setPeticionesTransito((prev) => prev - 1);
      });
  }

  useEffect(() => {
    setPagina(1);
    consultar();
  }, [busqueda, filtroEstado]);

  useEffect(() => {
    consultar();
  }, [pagina]);

  function handleAnteriorPagina() {
    if (pagina > 1) {
      setPagina(pagina - 1);
    }
  }

  function handleSiguientePagina() {
    setPagina(pagina + 1);
  }

  let contenido;

  if (peticionesTransito > 0) {
    contenido = (
      <tr>
        <td colSpan="6">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </td>
      </tr>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = pedidosFiltrados.map((pedido) => (
      <tr key={pedido.id_pedido}>
        <td className="align-middle text-center">
          <a target="_blank" href={API_URL + "z03/pdf/" + pedido.id_pedido} role="button" className="btn btn-danger">
            PDF
          </a>
        </td>
        <td className="align-middle text-center">{pedido.id_pedido}</td>
        <td className="align-middle text-center">
          {`${new Date(pedido["fecha"]).getUTCDate()}/${new Date(pedido["fecha"]).getUTCMonth() + 1}/${new Date(
            pedido["fecha"]
          ).getUTCFullYear()}`}
        </td>
        <td className="align-middle text-center">{pedido.nombre}</td>
        <td className="align-middle text-center">{pedido.tipo_entrega}</td>
        <td className="align-middle text-center">{pedido.estado}</td>
      </tr>
    ));
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      <p>Seleccione el pedido que desea exportar.</p>
      <form>
        <div className="row g-3 mb-3">
          <div className="col-sm-8">
            <input
              type="text"
              placeholder="Buscar por número, vendedor, fecha, cliente, teléfono o DNI..."
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
              className="form-control"
              id="busqueda"
            />
          </div>
          <div className="col-sm-4">
            <select
              className="form-select"
              value={filtroEstado}
              onChange={(e) => setFiltroEstado(e.target.value)}
              aria-label="Default select example"
            >
              <option value="todos">Todos...</option>
              <option value="abierto">Abierto</option>
              <option value="completado">Completado</option>
              <option value="en transito">En transito</option>
            </select>
          </div>
        </div>
      </form>
      <div>
        <div className="row mb-0 g-3">
          <div className="col-md-auto">
            <nav>
              <ul className="pagination">
                {pagina > 1 && (
                  <li className="page-item">
                    <button className="page-link" onClick={() => setPagina(1)}>
                      1
                    </button>
                  </li>
                )}
                {pagina > 3 && (
                  <li className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                )}
                {pagina > 2 && (
                  <li className="page-item">
                    <button className="page-link" onClick={handleAnteriorPagina}>
                      {pagina - 1}
                    </button>
                  </li>
                )}
                <li className="page-item active">
                  <span className="page-link">{pagina}</span>
                </li>
                {pagina < totalPaginas - 1 && (
                  <li className="page-item">
                    <button className="page-link" onClick={handleSiguientePagina}>
                      {pagina + 1}
                    </button>
                  </li>
                )}
                {pagina < totalPaginas - 2 && (
                  <li className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                )}
                {pagina < totalPaginas && (
                  <li className="page-item">
                    <button className="page-link" onClick={() => setPagina(totalPaginas)}>
                      {totalPaginas}
                    </button>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th className="align-middle text-center" scope="col">
                PDF
              </th>
              <th className="align-middle text-center" scope="col">
                Número
              </th>
              <th className="align-middle text-center" scope="col">
                Fecha
              </th>
              <th className="align-middle text-center" scope="col">
                Cliente
              </th>
              <th className="align-middle text-center" scope="col">
                Tipo de entrega
              </th>
              <th className="align-middle text-center" scope="col">
                Estado
              </th>
            </tr>
          </thead>
          <tbody id="tabla">{contenido}</tbody>
        </table>
      </div>
    </main>
  );
}
