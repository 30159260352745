const desarrollo = 0

if (desarrollo) {
  var varApiUrl = "http://localhost:5000/"
  var varStaticUrl = "http://localhost:5000/static/"
} else {
  var varApiUrl = "https://api.sistema.bluemoon.nexotech.pe/"
  var varStaticUrl = "https://api.sistema.bluemoon.nexotech.pe/static/"
}

export const API_URL = varApiUrl
export const STATIC_URL = varApiUrl
